import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

// const Image = ({ headerLogo = false, personal = false, footerLogo = false, holgerBauer = false, juergenSchneider = false, secretary = false, map = false }) => {
//     const data = useStaticQuery(graphql`
//         query {
//             headerLogo: file(relativePath: { eq: "logos/bauer-pauly-header-logo.png" }) {
//                 childImageSharp {
//                     fluid(maxWidth: 1200) {
//                         ...GatsbyImageSharpFluid
//                     }
//                 }
//             }
//             personal: file(relativePath: { eq: "personal.jpg" }) {
//                 childImageSharp {
//                     fluid(maxWidth: 1200) {
//                         ...GatsbyImageSharpFluid
//                     }
//                 }
//             }
//             footerLogo: file(relativePath: { eq: "logos/footer-logo.png" }) {
//                 childImageSharp {
//                     fixed(width: 260, quality: 100) {
//                         ...GatsbyImageSharpFixed
//                     }
//                 }
//             }
//             holgerBauer: file(relativePath: { eq: "team-member/holgerbauer.jpg" }) {
//                 childImageSharp {
//                     fluid(maxWidth: 1200) {
//                         ...GatsbyImageSharpFluid
//                     }
//                 }
//             }
//             juergenSchneider: file(relativePath: { eq: "team-member/juergenschneider.jpg" }) {
//                 childImageSharp {
//                     fluid(maxWidth: 1200) {
//                         ...GatsbyImageSharpFluid
//                     }
//                 }
//             }
//             secretary: file(relativePath: { eq: "team-member/secretary.jpg" }) {
//                 childImageSharp {
//                     fluid(maxWidth: 1200) {
//                         ...GatsbyImageSharpFluid
//                     }
//                 }
//             }
//             map: file(relativePath: { eq: "map.png" }) {
//                 childImageSharp {
//                     fluid(maxWidth: 1200) {
//                         ...GatsbyImageSharpFluid
//                     }
//                 }
//             }
//         }
//     `);
//
//     if (headerLogo === true) {
//         return <Img fluid={data.headerLogo.childImageSharp.fluid} />;
//     }
//     if (personal === true) {
//         return <Img className="personal-image" fluid={data.personal.childImageSharp.fluid} />;
//     }
//     if (footerLogo === true) {
//         return <Img fixed={data.footerLogo.childImageSharp.fixed} />;
//     }
//     if (holgerBauer === true) {
//         return <Img fluid={data.holgerBauer.childImageSharp.fluid} />;
//     }
//     if (juergenSchneider === true) {
//         return <Img fluid={data.juergenSchneider.childImageSharp.fluid} />;
//     }
//     if (secretary === true) {
//         return <Img fluid={data.secretary.childImageSharp.fluid} />;
//     }
//     if (map === true) {
//         return <Img className="map" fluid={data.map.childImageSharp.fluid} />;
//     } else {
//         return null;
//     }
// };

// export default Image;

export const FluidImage = ({ name, className = '' }) => {
    const data = useStaticQuery(
        graphql`
            query {
                allImageSharp {
                    edges {
                        node {
                            fluid(maxWidth: 1600) {
                                ...GatsbyImageSharpFluid
                                originalName
                            }
                        }
                    }
                }
            }
        `
    );

    const image = findImageForName(data.allImageSharp.edges, name, 'fluid');

    return name !== null ? <Img className={className} fluid={image.fluid} /> : null;
};

const findImageForName = (edges, name, prop) => {
    let image = null;
    edges.forEach(edge => {
        if (edge.node[prop].originalName === name) {
            image = edge.node;
        }
    });

    return image;
};
