import React from 'react';
import { FluidImage } from './fluidImage';
import { FixedImage } from './fixedImage';
import { Link } from 'gatsby';

const Footer = ({ withMap = true }) => (
    <footer>
        {withMap && <FluidImage className="map" name="map.png" />}
        <div className="container mt-5">
            <div className="row">
                <div className="col-12 col-md-5 footer-logo">
                    <FixedImage className="footer-logo" name="footer-logo.png" />
                </div>
                <div className="col-12 col-md-7 footer-contact">
                    <div className="row">
                        <div className="col-12 col-sm-6 footer-text">
                            <p>
                                <strong>Bauer &amp; Dr. Pauly</strong>
                                <br />
                                <strong>Rechtsanwälte</strong>
                                <br />
                                Furtbachstr. 10a
                                <br />
                                70178 Stuttgart
                            </p>
                            <p>
                                T +49 (0) 711 4698777
                                <br />F +49 (0) 711 4698775
                            </p>
                            <p>kanzlei@bauerpauly.de</p>
                        </div>
                        <div className="col-12 col-sm-6 footer-menu">
                            <div className="menu-footer-menu-container">
                                <ul className="menu">
                                    <li className="footer-nav-item">
                                        <Link className="text-uppercase" to="/impressum">
                                            Impressum
                                        </Link>
                                    </li>
                                    <li className="footer-nav-item">
                                        <Link className="text-uppercase" to="/datenschutz">
                                            Datenschutz
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 copy-right">
                            <p>© Bauer &amp; Pauly. Alle Rechte vorbehalten.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
