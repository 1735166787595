import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

export const FixedImage = ({ name, className = '' }) => {
    const data = useStaticQuery(
        graphql`
            query {
                allImageSharp {
                    edges {
                        node {
                            fixed(width: 260, quality: 100) {
                                ...GatsbyImageSharpFixed
                                originalName
                            }
                        }
                    }
                }
            }
        `
    );
    const image = findImageForName(data.allImageSharp.edges, name, 'fixed');

    return name !== null ? <Img className={className} fixed={image.fixed} /> : null;
};

const findImageForName = (edges, name, prop) => {
    let image = null;
    edges.forEach(edge => {
        if (edge.node[prop].originalName === name) {
            image = edge.node;
        }
    });

    return image;
};
