import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Logo from '../images/logos/bauer-pauly-header-logo.png';

export default class Header extends React.Component {
    state = {
        navCollapsed: true,
    };

    navToggle = () => this.setState({ navCollapsed: !this.state.navCollapsed });

    render() {
        const collapsed = this.state.navCollapsed;
        return (
            <header className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-8 col-lg-4 pl-0">
                            <div className="logo">
                                <Link to="/">
                                    <img src={Logo} alt="Bauer & Dr.Pauly" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-4 col-lg-8 d-flex align-items-center justify-content-end">
                            <div className={`menu-button ${collapsed && 'show'}`} onClick={this.navToggle}>
                                <div className={`btn-line ${collapsed || 'show'}`} />
                                <div className={`btn-line ${collapsed || 'show'}`} />
                                <div className={`btn-line ${collapsed || 'show'}`} />
                            </div>
                            <div className={`fullscreen-nav ${collapsed && 'hide'}`}>
                                <ul>
                                    <li>
                                        <Link onClick={this.navToggle} to="/" className="text-uppercase">
                                            kanzlei
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={this.navToggle} to="/anwaelte" className="text-uppercase">
                                            anwälte
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={this.navToggle} to="/kompetenzen" className="text-uppercase">
                                            kompetenzen
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={this.navToggle} to="/kontakt" className="text-uppercase">
                                            kontakt
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="menu-top-menu-container">
                                <ul className="d-flex list-unstyled justify-content-end align-items-center menu p-0 m-0">
                                    <li>
                                        <Link to="/" className="text-uppercase">
                                            kanzlei
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/anwaelte" className="text-uppercase">
                                            anwälte
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/kompetenzen" className="text-uppercase">
                                            kompetenzen
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={this.navToggle} to="/kontakt" className="text-uppercase">
                                            kontakt
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};
